import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { EventService } from '@ebursa/event/services/event.service';
import { Observable } from 'rxjs';

/**
 * Komponen untuk halaman Check-In acara.
 * Mengelola proses check-in pengguna dan menampilkan status loading, error, atau sukses.
 * @author Bagas Prasetyadi
 */

@Component({
    selector: 'ebursa-event-check-in-page',
    templateUrl: './event-check-in.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCheckInPage implements OnInit {
    public isDialogOpen = false;
    public constructor(
        private activatedRoute: ActivatedRoute,
        private eventService: EventService,
        private eventRepository: EventRepository,
    ) {}

    public get isLoading$(): Observable<boolean> {
        return this.eventRepository.isLoading$();
    }

    public get error$(): Observable<string> {
        return this.eventRepository.getError$();
    }

    ngOnInit() {
        const { event } = this.activatedRoute.snapshot.params;

        this.eventService.checkInEvent(event).subscribe();
    }
}
